import React from "react"
import { css } from "@emotion/react"
import Section from "../Section/Section"
import Beginner from "../Content/StartHere/Beginner"
import Advanced from "../Content/StartHere/Advanced"
import DelimiterSVG, { SVGDelimiter } from "../Section/SVGDelimiter"

const TrialOption = ({ state, h2, h5, location, toggleSection, setToggleSection }) => {

  const handleSection = (n) => {
    setToggleSection(n)
  }

  return (
    <>
      <Section
        Background={`#010921`}
      >

        <div css={TrialOptionStyles}>

          {h2 !== undefined &&
          <h2 dangerouslySetInnerHTML={{ __html: h2 }} />
          }
          {h5 !== undefined &&
          <h5 dangerouslySetInnerHTML={{ __html: h5 }} />
          }

          <p className="select">Select an option :</p>
          <div id="option-content-ctn">
            <button onClick={() => handleSection(true)} className={state ? "option-ctn active" : "option-ctn"}>
              <img src="/Experiment.png" alt="advanced" />
              <p>THIS IS MY <br />FIRST TRIAL</p>
            </button>

            <button onClick={() => handleSection(false)} className={!state ? "option-ctn active" : "option-ctn"}>
              <img src="/laboratory.png" alt="advanced" />
              <p>I'M AN ADVANCED <br />TRIAL MANAGER</p>
            </button>
          </div>
        </div>
      </Section>

      {toggleSection !== undefined && (
        toggleSection ?
          <Beginner location={location} />
          :
          <>
            <DelimiterSVG
              shape={SVGDelimiter.Shape.Line}
              fillColor={`#010921`}
              Background={`transparent`}
            />
            <Advanced location={location} />
          </>
      )}

    </>
  )
}

export default TrialOption

const TrialOptionStyles = css`
  #option-content-ctn {
    display: flex;
    margin: auto;
    justify-content: center;
  }

  .select {
    text-align: center;
    color: #fff;
    font-weight: 300 !important;
  }

  .active {
    box-shadow: 0 0 4px 1px rgba(255, 255, 255, 0.47);
  }

  .option-ctn {
    min-width: 255px;
    width: 330px;
    height: 100px;
    display: flex;
    border: #fff solid 2px;
    border-radius: 0.2rem;
    cursor: pointer;
    background: transparent;

    img {
      width: 18.5%;
      margin: auto 0 auto 1.2rem;
    }

    p {
      font-weight: 300;
      text-align: start;
      margin: auto;
      color: #fff;
    }
  }

  .option-ctn:first-of-type {
    margin-right: 2.5rem;
  }

  @media screen and (max-width: 850px) {

    #option-content-ctn {
      width: 100%;
      flex-direction: column;
      align-items: center;
    }

    .option-ctn {
      margin-right: 0 !important;
    }

    .option-ctn:first-child {
      margin-bottom: 2rem;
    }

  }

`
