import React from "react"
import IntroDataManagement from "./IntroDataManagement"
import Section from "../../Section/Section"
import ContactComponent from "./../Contact/ContactComponent"
import DelimiterSVG, { SVGDelimiter } from "../../Section/SVGDelimiter"
import StudySteps from "./../StudySteps/StudySteps"
import EbookComponent from "./../Ebooks/EbookComponent"
import Footer from "../../Bar/footer"
import ContactCTA from "../Contact/ContactCTA"

export default function Beginner(location) {
  return (
    <>
      <Section
        Background={"#010921"}
        data-menuanchor="fourthPage"
      >
        <div style={{
          color: "white",
          textAlign: "center",
          paddingBottom: "1rem"
        }}>
          <p>Not sure where to start your first trial ?</p>
          <p>Here is a short guide including main steps <br />
            and resources you will need.</p>
        </div>

        <ContactCTA
          h2={"The Quick way"}
          h5={"Avoid confusion and kickstart your trial:"}
          scrollTo={8}
        />

      </Section>

      <Section
        Background={"#010921"}
        data-menuanchor="fourdsfthPage"
      >
        <IntroDataManagement />
      </Section>
      <DelimiterSVG
        shape={SVGDelimiter.Shape.Rounded}
        fillColor={"#010921"}
        Background={`#2B4D83`}
      />

      <Section
        GradientBackground={`linear-gradient(180deg,rgba(43, 77, 131, 1) 0%,rgba(1, 10, 127, 1) 100%)`}
      >
        <StudySteps
          h2={`YOUR STUDY WITHIN 4 STEPS`}
          h5={`Here is a common trial blueprint`}
        />
      </Section>

      <DelimiterSVG
        shape={SVGDelimiter.Shape.Rounded}
        fillColor={"#010A7F"}
        Background={`#010921`}
      />

      <Section
      Background={"#010921"}>
        <EbookComponent
          h2={`What documents do you need ?`}
          h5={`The Trial Master File Contains The Data Documents You Need`}
          EbookId={1}
        />
      </Section>

      <DelimiterSVG
        shape={SVGDelimiter.Shape.Line}
        fillColor={"#010921"}
        Background={`transparent`}
      />

      <ContactComponent
        DisplayContactForm
      />

      <Footer location={location} />
    </>
  )
}