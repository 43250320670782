import React from "react"
import Footer from "../../Bar/footer"
import ContactComponent from "./../Contact/ContactComponent"

export default function Advanced(location) {

  return (
    <>
      <ContactComponent
        DisplayContactForm={true}
        children={<>
          <h1>Please Contact our experts.</h1>
          <p>They will guide you toward the solution</p>
          <p> most adapted to you project.</p>
        </>}
      />


      <Footer location={location} />
    </>
  )
}
