import React, { useEffect, useState } from "react"
import "../assets/styles/global.css"

import TrialOption from "../components/Button/option"
// import Rgpd from "../components/Bar/rgpd"
import FullPageLayout from "../layouts/FullPageLayout"
import { css } from "@emotion/react"
import Section from "../components/Section/Section"
import EbookComponent from "../components/Content/Ebooks/EbookComponent"
import {
  ADVANCED_START_HERE_TOOLTIPS,
  BEGGINER_START_HERE_TOOLTIPS,
  DEFAULT_START_HERE_TOOLTIPS
} from "../const/MenuTooltips"
import { SEO_OPTIONS_START_HERE } from "../const/SEOOptions"

export default function StartHere({ location }) {

  const [toggleSection, setToggleSection] = useState(undefined)

  const fullpageOptions = {
    navigationTooltips: toggleSection === true ?
      BEGGINER_START_HERE_TOOLTIPS :
      toggleSection === false ? ADVANCED_START_HERE_TOOLTIPS : DEFAULT_START_HERE_TOOLTIPS
  }

  useEffect(() => {
    if (toggleSection !== undefined) {
      window.fullpage_api.silentMoveTo(2, 0)
      window.fullpage_api.moveTo(3, 0)
    }

  }, [toggleSection])

  return (
    <FullPageLayout
      css={StartHereStyle}
      options={fullpageOptions}
      SEOOptions={SEO_OPTIONS_START_HERE}
      location={location}
    >
      <Section isFirstSection
        Background={`#010921`}
        autoHeight
        SectionMinHeight={"100vh"}
        Padding={"25vh 0 0 0"}
      >

        <EbookComponent
          EbookId={2}
          isOnEbookPage
        />

        <h2 className="start">
          Start your trial here
          <hr />
        </h2>
      </Section>

      <TrialOption
        h2={`Got a trial ?`}
        h5={`Find your suitable process</br>to extract reliable data`}
        location={location}
        setToggleSection={setToggleSection}
        toggleSection={toggleSection}
      />


      {/* <Rgpd location={location} /> */}
    </FullPageLayout>
  )
}


const StartHereStyle = css`
  .start {
    cursor: pointer !important;
    margin-top: 4rem !important;
  }

  hr {
    width: 8rem
  }

  #StartHereContent {
    padding-top: 4rem;
  }

  header {
    background-size: cover;
    background-position: center;
    margin-bottom: -1px;
    display: flex;
    flex-direction: column;
    background-color: #010921;

  }

  header h1 {
    color: #f5f5f5;
    text-transform: uppercase;
    font-size: 3.75842535rem;
    font-weight: 400;
    letter-spacing: 1.44rem;
    text-align: center;
    margin-bottom: 3rem;
    margin-top: 0;
    line-height: 1.35;
  }

  header p {
    color: #f1f1f1;
    text-align: center;
    margin: auto;
    margin-bottom: 3.4rem;
    max-width: 25rem;
    line-height: 1.4;
  }

  .BG {
    h1 {
      margin-top: 0;
    }
  }

  #navbar {
    width: 100%;
  }

  #navbar-content-ctn {
    max-width: 78.2525204rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 8.75rem;
  }

  #navbar-content-ctn > a {
    position: relative;
    left: -0.72rem;
  }

  #logo {
    width: 11.52rem;
    display: block;
  }

  #header-content-ctn {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fixed-navbar {
    position: fixed !important;
    top: 0 !important;
    margin-bottom: 0 !important;
    z-index: 1 !important;
    background-color: #100d24 !important;
    border-bottom: 0.0625rem solid #e2e3e6 !important;

    .submenu {
      background-color: #100d24;
    }
  }

  .fixed-navbar-content-ctn {
    flex-direction: row !important;
    height: auto !important;
  }

  @media (max-width: 1023px) {
    header {
      justify-content: flex-end;
    }

    #navbar {
      position: fixed;
      top: 0;
      margin-bottom: 0;
      border-bottom: 0.0625rem solid #e2e3e6;
      z-index: 1;
    }

    #navbar-content-ctn {
      flex-direction: row;
      height: auto;
      background-color: #100d24;
    }

    #header-content-ctn {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      max-height: calc(100% - 3.421875rem);
    }

    #logo {
      width: 8rem;
    }
  }

  @media (max-width: 767px) {
    header h1 {
      letter-spacing: 1rem;
    }
  }

  @media (max-width: 479px) {
    header h1 {
      letter-spacing: 0.6rem;
    }
  }

  @media (max-width: 424px) {
    header h1 {
      letter-spacing: 0.3rem;
    }
  }

  @media (max-width: 374px) {
    header h1 {
      letter-spacing: 0;
    }
  }

  @media (max-height: 600px) {
    header h1 {
      font-size: 2.37rem;
      margin-bottom: 2rem;
    }

    header p {
      margin-bottom: 2.4rem;
    }
  }
`





