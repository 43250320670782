import React from "react"
import { css } from "@emotion/react"

const IntroDataManagement = () => {
  return (
    <div css={IntroDataManagementStyles}>
      <h2>Why you need data management</h2>
      <h5>A quick intro to health data management</h5>
      <div className="intro">

        <div className="ctn">
          <div className="subtitle">
            <p>Why do you need data ?</p>
          </div>
          <p>
            One key objective of health leaders is to make their drug or medical
            device viable, useful and marketable.
          </p>
          <p>
            Therefore they use data management and biostatistics to collect, stock,
            manage and analyse data about their solution.
          </p>
        </div>

        <div className="ctn">
          <div className="subtitle">
            <p>Data management and your success</p>
          </div>
          <p>In order to be approved by regulatory submission, those data need to
            follow a strict process. A single irregularity can cancel everything.
          </p>
          <p>
            Those are the responsibilities of a Contract Research Organization (CRO)
            such as Focus DataScience. We make your life easier by taking care of
            your data.
          </p>
        </div>
      </div>
    </div>
  )
}

export default IntroDataManagement

const IntroDataManagementStyles = css`

  .subtitle {
    color: #0078FF;
    margin: 1rem 0;
    font-weight: 500 !important;
  }

  .intro {
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    position: center;
  }

  .ctn {
    width: 63%;
    margin: auto;
    max-width: 600px;

    p {
      margin: 0 0 0.7rem 0
    } 
    p:last-of-type {
        margin: 0 0 2em 0
      }
  }

  .ctn:first-of-type {
    margin-bottom: 4rem;
  }

  p {
    text-align: start;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    font-size: 1.35rem;
  }

  h5 {
    font-size: 1.47rem;
    font-weight: 300;
  }
  

  @media (max-width: 700px) {
    .ctn  {
      width: 60%;
    }
  }

  @media (max-width: 600px) {
    .ctn {
      width: 70%;
      p{
        font-size: 1.25em;
      }
    }
    h2 {
      font-size: 2.15rem;
    }
  }
`